<template>
	<div class="">
		<!-- Cropper Modal -->
		<div v-if="showCropper" class="cropper-modal-custom">
			<div class="cropper-content">
				<vue-cropper ref="cropper" :src="selectedImage" :aspect-ratio="1" :view-mode="1" :auto-crop-area="1"
					:responsive="true" :background="false"></vue-cropper>

				<div class="button-container" style="margin-top: 10px;">
					<button class="button-common save cropper-button" @click="confirmCrop">Crop & Upload</button>
					<button class="button-common cancel cropper-button" @click="cancelCrop">Cancel</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { onMounted, ref } from 'vue';
import { useCropper } from '@/composables/useCropper';

const cropper = ref();
const { 
	selectedImage,
	showCropper,
	cropperRef,
	confirmCrop,
	cancelCrop,
} = useCropper();

onMounted(() => {
	cropperRef.value = cropper;
})

</script>

<style scoped>
.cropper-modal-custom {
  position: fixed;
  top: 15px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.cropper-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 60px;
	width: 25% !important;
	height: 50% !important;
}

.cropper-content > div:first-of-type {
	height: 100%;
	width: 100%;
	overflow: hidden;
	max-height: 350px;
}
</style>