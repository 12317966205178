export function transformKeyToSnakeCase(data: { [key: string | number]: any }, seen = new WeakSet()): any {
  if (!data || typeof data !== 'object') return data;

  // Prevent infinite recursion by skipping already visited objects
  if (seen.has(data)) {
    return data;
  }

  seen.add(data);

  if (Array.isArray(data)) {
    return data.map((item) => transformKeyToSnakeCase(item));
  }

  const transformedObject: { [key: string]: any } = {};
  for (const [key, value] of Object.entries(data)) {
		if(seen.has(value)) {
			continue;
		}
    const newKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
    transformedObject[newKey] = transformKeyToSnakeCase(value, seen);
  }
  return transformedObject;
}

export function transformKeyToCamelCase(data: { [key: string | number]: any }, seen = new WeakSet()): any {
  if (!data || typeof data !== 'object') return data;

  // Prevent infinite recursion by skipping already visited objects
  if (seen.has(data)) {
    return data;
  }

  seen.add(data);

  if (Array.isArray(data)) {
    return data.map((item) => transformKeyToCamelCase(item, seen));
  }

  const transformedObject: { [key: string]: any } = {};
  for (const [key, value] of Object.entries(data)) {
    const newKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
    transformedObject[newKey] = transformKeyToCamelCase(value, seen);
  }

  return transformedObject;
}

