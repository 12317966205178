<template>
    <div
      :class="['menu-button', buttonVariant, className]"
      @mouseover="cursorHover(true)"
      @mouseleave="cursorHover(false)"
      :style="{ height: buttonSize + 'px', width: buttonSize + 'px' }"
    >
      <router-link v-if="to" :to="to" class="link-wrapper">
        <div class="icon-text-container">
          <div class="notification-circle" v-if="notification > 0">{{ notification }}</div>
          <div v-html="svgIcon" class="vector" :style="{ height: iconSize + 'px', width: iconSize + 'px' }"></div>
          <div class="text-wrapper">{{ buttonText }}</div>
        </div>
      </router-link>
			<div v-else-if="toHref" @click="redirectToExternalHttps" class="link-wrapper">
        <div class="icon-text-container">
          <div class="notification-circle" v-if="notification > 0">{{ notification }}</div>
          <div class="text-wrapper">{{ buttonText }}</div>
        </div>
      </div>
      <div v-else>
        <div class="icon-text-container">
          <div class="notification-circle" v-if="notification > 0">{{ notification }}</div>
          <div v-html="svgIcon" class="vector" :style="{ height: iconSize + 'px', width: iconSize + 'px' }"></div>
          <div class="text-wrapper">{{ buttonText }}</div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  const svgModules = import.meta.globEager('@/assets/*.svg');
  
  export default {
    name: "MenuButton",
    props: {
      property1: {
        type: String,
        default: "default",
        validator: function (value) {
          return ["variant-2", "default"].indexOf(value) !== -1;
        },
      },
      className: {
        type: String,
        default: "",
      },
      svgSrc: {
        type: String,
        required: true,
      },
      altText: {
        type: String,
        default: "Vector",
      },
      buttonText: {
        type: String,
        default: "saved",
      },
      to: {
        type: String,
        default: null,
      },
			toHref: {
				type: String,
				default: null,
			},
      routeName: {
        type: String,
        default: null,
      },
      notification: {
        type: [Number, String],
        default: null,
      },
      size: { // New prop
        type: Number,
        default: 23, // Default size in px
      },
    },
    data() {
      return {
        svgIcon: '',
      };
    },
    computed: {
      buttonVariant() {
        return this.$route.name === this.routeName ? 'variant-2' : this.property1;
      },
      showNotification() {
        return typeof this.notification === 'number';
      },
      buttonSize() {
        return this.size ; // Example calculation for container size
      },
      iconSize() {
        return this.size;
      }
    },
    mounted() {
      this.fetchSvgIcon();
    },
    watch: {
      svgSrc: 'fetchSvgIcon',
      $route(to, from) {
      }
    },
    methods: {
      fetchSvgIcon() {
        const svgModule = svgModules[`/src/assets/${this.svgSrc}`];
        if (svgModule) {
          fetch(svgModule.default)
            .then(response => response.text())
            .then(svg => {
              const cleanedSvg = svg.replace(/fill="[^"]*"/g, ''); // Remove existing fill attributes
              this.svgIcon = cleanedSvg;
            })
            .catch(error => console.error('Error fetching SVG:', error));
        } else {
          console.error(`SVG not found: ${this.svgSrc}`);
        }
      },
      cursorHover(isHovering) {
        document.body.style.cursor = isHovering ? "pointer" : "default";
      },
			redirectToExternalHttps() {
				window.location.href = this.toHref;
			}
    },
  };
  </script>
  
  
  <style scoped>
  .menu-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 78px;
    position: relative;
  }
  
  .icon-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .menu-button .text-wrapper {
    font-family: "Inter-Light", Helvetica;
    font-size: 9px;
    font-weight: 300;
    text-align: center;
    margin-top: 5px;
  }
  
  .menu-button .vector {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    margin-bottom: 2px;
    fill: currentColor;
  }
  
  .notification-circle {
    position: absolute;
    top: 3px;
    left: 18px;
    height: 18px;
    width: 18px;
    background-color: #FF832B;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 10px;
    font-weight: light;
  }
  
  .menu-button.variant-2 {
    background-color: #056cf2;
    color: rgba(255, 255, 255, 0.5);
  }
  
  .menu-button.default {
    background-color: #056cf2;
    color: #056cf2;
  }
  
  .menu-button.variant-2 .text-wrapper,
  .menu-button.variant-2 .vector {
    color: rgba(255, 255, 255, 0.5); 
  }
  
  .menu-button.default .text-wrapper,
  .menu-button.default .vector {
    color: #ffffff; 
  }
  
  .link-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-decoration: none;
  }
  </style>