// store/modules/skills.js
import { v4 as uuidv4 } from 'uuid';
import { Skill } from '@/types';
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import apiClient from '@/axios';
import { auth } from '@/firebase';
import { is } from 'date-fns/locale';

export const useSkillsStore = defineStore('skills', () => {

	const skills = ref<Skill[]>([])
	const isAuthenticated = computed(() => auth.currentUser !== null);

	const getSkillById = (id: string) => {
		skills.value.find((skill: Skill) => skill.id === id)
	}

	const isSkillsVerified = computed(() => {
		if (skills.value.length === 0) return false;

		const changedSkillsCount = skills.value.filter(
			(skill) => skill.changeCounter > 0
		).length;

		return changedSkillsCount >= skills.value.length / 2;
	})

	const areSkillImproved = computed(() => {
		if (skills.value.length === 0) return false;

		const changedSkillsCount = skills.value.filter(
			(skill) => skill.improve > 0
		).length;

		return changedSkillsCount >= skills.value.length / 2;
	})


	async function setSkills(rawSkillsData: Skill[] | { skills: Skill[] }) {

		let skillsArray = [];

		if (Array.isArray(rawSkillsData)) {
			skillsArray = rawSkillsData;
		} else if (rawSkillsData && Array.isArray(rawSkillsData.skills)) {
			skillsArray = rawSkillsData.skills;
		} else {
			console.warn("setSkills called with invalid data, ignoring.");
			return;
		}

		if (skillsArray.length === 0) {
			console.warn("setSkills called with empty data, ignoring.");
			return;
		}

		const processedSkills = skillsArray.map((skill: Skill) => ({
			mainSkill: skill.mainSkill,
			subSkills: (skill.subSkills || []).map((subSkill) => ({
				name: subSkill.name,
				weight: subSkill.level || subSkill.weight || 0,
				type: subSkill.type,
				changeCounter: subSkill.changeCounter || 0,
				improve: subSkill.improve || 0,
				id: subSkill.id || uuidv4(),
				isShownOnSummary: subSkill.isShownOnSummary || false,
				preferredIndex: subSkill.preferredIndex || 0,
			})),
			type: skill.type,
			weight: skill.level || skill.weight || 0,
			changeCounter: skill.changeCounter || 0,
			improve: skill.improve || 0,
			id: skill.id || uuidv4(),
			isShownOnSummary: skill.isShownOnSummary || false,
			preferredIndex: skill.preferredIndex || 0,
		}));

		skills.value = processedSkills;
	}

	const resetAllChecked = () => {
		skills.value.forEach((skill: Skill) => {
			skill.checked = false;
		});
	}

	const updateSkillChecked = (payload: { id: string, checked: boolean }) => {
		const skill = skills.value.find((skill) => skill.id === payload.id);
		if (skill) {
			skill.checked = payload.checked;
			skill.changeCounter = (skill.changeCounter || 0) + 1;
		}
	}

	const initializeSkills = () => {
		if (!Array.isArray(skills.value)) {
			console.error("state.skills is not an array or is undefined:", skills.value);
			return;
		}

		const updatedSkills = skills.value.map((skill: Skill) => ({
			...skill,
			id: skill.id || uuidv4(),
			subSkills: (skill.subSkills || []).map((subSkill) => ({
				...subSkill,
				id: subSkill.id || uuidv4(),
			})),
		}));


		skills.value = updatedSkills;
	}

	const addSkill = (skill: Skill) => {
		const newSkill = {
			...skill,
			id: skill.id || uuidv4(),
			subSkills: (skill.subSkills || []).map((subSkill) => ({
				...subSkill,
				id: subSkill.id || uuidv4(),
			})),
		};

		skills.value.push({
			mainSkill: newSkill.mainSkill,
			weight: newSkill.weight || 0,
			subSkills: (newSkill.subSkills || []).map((subSkill) => ({
				...subSkill,
				id: subSkill.id || uuidv4(),
				weight: subSkill.weight || 0,
				changeCounter: 0,
				improve: subSkill.improve || 0,
				newSkill: subSkill.newSkill,
			})),
			changeCounter: 1,
			improve: newSkill.improve || 0,
			id: newSkill.id || uuidv4(),
			newSkill: newSkill.newSkill || false,
			checked: newSkill.checked || false,
		});

		// Push to dataLayer
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'skill_added',
			skill: {
				id: newSkill.id,
				name: newSkill.mainSkill,
			},
		});
	}

	const deleteSkill = (id: string) => {;
		const skill = skills.value.find((s) => s.id === id);
		if (skill) {
			skills.value = skills.value.filter((skill: Skill) => skill.id !== id);

			// Push to dataLayer
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: 'skill_deleted',
				skill: {
					id: skill.id,
					name: skill.mainSkill,
				},
			});
		} else {
			console.error(`Skill with id ${id} not found.`);
		}
	}

	const updateSkill = (data: {id: string, skill: Skill}) => {
		const { id, skill } = data;
		const existingSkill = skills.value.find((s) => s.id === id);
		if (existingSkill) {
			existingSkill.mainSkill = skill.mainSkill;
			existingSkill.weight = skill.weight;
			existingSkill.improve = skill.improve || 0;
			existingSkill.changeCounter = (existingSkill.changeCounter || 0) + 1;
			existingSkill.preferredIndex = skill.preferredIndex;
			existingSkill.isShownOnSummary = skill.isShownOnSummary;

			if (!existingSkill.subSkills) {
				existingSkill.subSkills = [];
			}

			skill.subSkills.forEach((subSkill) => {
				const existingSubSkill = existingSkill.subSkills.find(
					(ss) => ss.id === subSkill.id
				);
				if (existingSubSkill) {
					Object.assign(existingSubSkill, subSkill);
				} else {
					existingSkill.subSkills.push({
						...subSkill,
						id: subSkill.id || uuidv4(),
					});
				}
			});

			existingSkill.subSkills = existingSkill.subSkills.filter((existingSubSkill) =>
				skill.subSkills.some((subSkill) => subSkill.id === existingSubSkill.id)
			);

		} else {
			console.error(`No existing skill found with id ${id}`);
		}

		// Push to dataLayer
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'skill_edited',
			skill: {
				id: id,
				name: skill.mainSkill,
			},
		});
	}

	async function saveSkills(skills: Skill[]){
		const profileState = localStorage.getItem('profileState')
    ? JSON.parse(localStorage.getItem('profileState') as string)
    : null;

		if(!isAuthenticated.value && profileState) {
			profileState.skills = skills;
			localStorage.setItem('profileState', JSON.stringify(profileState));
		} else {
			apiClient.post('/save_state', {
				partialState: {
					'skills': skills,
				}
			})
		}
	}

	const updateSkillWeight = (payload: { skillId: string, newWeight: number }) => {
		const skill = skills.value.find((s) => s.id === payload.skillId);
		if (skill) {
			skill.weight = payload.newWeight;
			skill.changeCounter = (skill.changeCounter || 0) + 1;
		}

		// Push to dataLayer
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'skill_weight_updated',
			skill: {
				id: payload.skillId,
				newWeight: payload.newWeight,
			},
		});
	}

	const updateSubSkillWeight = (payload: { skillId: string, subSkillId: string, weight: number }) => {

		const skill = skills.value.find((s) => s.id === payload.skillId);
		if (skill) {
			const subSkill = skill.subSkills.find((sub) => sub.id === payload.subSkillId);
			if (subSkill) {
				subSkill.weight = payload.weight;
				skill.changeCounter = (skill.changeCounter || 0) + 1;
			}
		}

		// Push to dataLayer
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'subskill_weight_updated',
			skill: {
				id: payload.skillId,
			},
			subSkill: {
				id: payload.subSkillId,
				newWeight: payload.weight,
			},
		});
	}

	const updateSkillImprove = (payload: { skillId: string, improve: number }) => {
		const skill = skills.value.find((s) => s.id === payload.skillId);
		if (skill) {
			skill.improve = payload.improve;
			skill.changeCounter = (skill.changeCounter || 0) + 1;
		}

		// Push to dataLayer
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'skill_improve_updated',
			skill: {
				id: payload.skillId,
				improve: payload.improve,
			},
		});
	}

	const updateSubSkillImprove = (payload: { skillId: string, subSkillId: string, improve: number }) => {
		const skill = skills.value.find((s) => s.id === payload.skillId);
		if (skill && skill.subSkills) {
			const subSkill = skill.subSkills.find((sub) => sub.id === payload.subSkillId);
			if (subSkill) {
				subSkill.improve = payload.improve;
				skill.changeCounter = (skill.changeCounter || 0) + 1;
			}
		}

		// Push to dataLayer
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'subskill_improve_updated',
			skill: {
				id: payload.skillId,
			},
			subSkill: {
				id: payload.subSkillId,
				improve: payload.improve,
			},
		});
	}

	const saveSkillImprovements = (selectedSkills: Skill[]) => {
		const totalSteps = selectedSkills.reduce(
			(total: number, skill: Skill) => total + (skill.improve - skill.weight),
			0
		);

		if (totalSteps <= 8) {
			selectedSkills.forEach((skill: Skill) => {
				const skillIndex = skills.value.findIndex((s) => s.id === skill.id);
				if (skillIndex !== -1) {
					// TODO: verify
					skills.value[skillIndex].weight = skill.improve;
					skills.value[skillIndex].changeCounter = (skills.value[skillIndex].changeCounter || 0) + 1;


					// Push to dataLayer
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						event: 'skill_improvement_saved',
						skill: {
							id: skill.id,
							improve: skill.improve,
						},
					});
				}
			});
		} else {
			console.error('Total steps exceed the maximum limit of 8.');
		}
	}

	return {
		skills,
		getSkillById,
		isSkillsVerified,
		areSkillImproved,
		setSkills,
		resetAllChecked,
		updateSkillChecked,
		initializeSkills,
		addSkill,
		deleteSkill,
		updateSkill,
		updateSkillWeight,
		updateSubSkillWeight,
		updateSkillImprove,
		updateSubSkillImprove,
		saveSkillImprovements,
		saveSkills
	}
})


