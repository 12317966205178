<template>
  <div>
    <div v-if="show && !isCapturing" class="modal-overlay" @click.self="close">
      <div class="modal-content">
        <h3>Feedback</h3>
        <textarea v-model="feedbackText" placeholder="Describe your feedback here..."></textarea>
        <div class="button-container">
          <button class="button-common save" @click="submitFeedback">Submit</button>
          <button class="button-common cancel" @click="close">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import html2canvas from 'html2canvas';
import apiClient from '@/axios';
import { useGlobalStore } from '@/store/store';
import { storeToRefs } from 'pinia';

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    componentTitle: {
      type: String,
      required: true,
    }
  },
	emits: ['close'],
  data() {
    return {
      feedbackText: '',
      scrollHeight: 0,
      isCapturing: false, // Flag to control modal visibility during capture
    };
  },
	setup() {
		const { personalInfo } = storeToRefs(useGlobalStore())
		const fullname = personalInfo.fullName
		return {
			fullname
		}
	},
  methods: {
    close() {
      this.$emit('close');
    },
    async submitFeedback() {
      this.isCapturing = true; // Hide the modal
      await this.$nextTick(); // Wait for the DOM update
      const canvas = await html2canvas(document.body);
      const screenshot = canvas.toDataURL('image/png');
      this.isCapturing = false; // Show the modal again

      const feedback = {
        user: this.fullname,
        text: this.feedbackText,
        page: window.location.href,
        scrollHeight: this.scrollHeight,
        componentTitle: this.componentTitle,
        screenshot: screenshot
      };
      this.sendFeedbackToServer(feedback);
    },
    sendFeedbackToServer(feedback) {
      apiClient.post('/feedback', feedback, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true
      })
      .then(response => {
        console.log('Feedback submitted successfully:', response.data);
        this.feedbackText = '';
        this.close();
      })
      .catch(error => {
        console.error('Error submitting feedback:', error);
      });
    },

    updateScrollHeight() {
      this.scrollHeight = window.scrollY;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScrollHeight);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScrollHeight);
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 0;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #e9eaeb;
  border-radius: 4px;
  margin-bottom: 20px;
}
</style>
