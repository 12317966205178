<template>
  <div :class="layoutClass">
    <!-- Menu Transition -->
    <transition :name="menuTransition" @after-leave="performRouteChange">
      <!-- Mobile Menu -->
      <Menu 
        v-if="isMenuVisible && isMobile" 
        @closeMenu="closeMenu" 
        @editProfile="handleProfileEdit" 
      />
    </transition>
      <!-- Desktop Menu -->
      <MenuDesktop 
        v-if="isMenuVisible && !isMobile" 
        @closeMenu="closeMenu" 
        @editProfile="handleProfileEdit()" 
      />

    <!-- Header for Desktop -->
    <header
        v-if="!isMobile"
        :class="['header-desktop', { 'header-hidden': !isHeaderVisible, 'sidebar-visible': isOverlayVisible }]"
      >
      <div class="header-content">
        <button class="lionhead-logo-button">
          <router-link to="/" class="logo-link">
            <img class="logo" alt="Group" src="@/assets/Skilleon-logo-symbol_blue.svg" />
            <span class="logo-text">LionHead </span>
            <div v-if="isAuthenticated" class="notification-beta">Beta</div>
          </router-link>
        </button>
        <div class="header-route-buttons">
          <!-- Jobs Button -->
					<MenuButtonHeader
						v-if="!isAuthenticated && route.path == '/cv'"
            className="header-button"
            svgSrc="bookmarks.svg"
            altText="Jobs Icon"
            buttonText="Jobs"
            to="/"
						@click="openPopup"
            routeName="Jobs"
            :overlayValue="false"
          />
					<MenuButtonHeader
						v-else
            className="header-button"
            svgSrc="bookmarks.svg"
            altText="Jobs Icon"
            buttonText="Jobs"
            to="/"
            routeName="Jobs"
            :overlayValue="false"
          />
          <!-- Bookmarks Button -->
          <MenuButtonHeader
						v-if="isAuthenticated"
            className="header-button"
            svgSrc="bookmarks.svg"
            altText="Saved Icon"
            buttonText="Bookmarks"
            to="/bookmarks"
            routeName="Bookmarks"
          />
					<MenuButtonHeader
						v-else
            className="header-button"
            svgSrc="bookmarks.svg"
            altText="Saved Icon"
            buttonText="Bookmarks"
						:to="route.path"
            @click="openPopup"
            routeName="Bookmarks"
          />
          <!-- Offers Button -->
          <!-- <MenuButtonHeader
            className="header-button"
            svgSrc="offer.svg"
            altText="Offer Icon"
            buttonText="Offer"
            to="/offers"
            routeName="Offers"
          /> -->
          <!-- CV Button -->
          <MenuButtonHeader
						v-if="isAuthenticated || profileState"
            className="header-button"
            svgSrc="CV.svg"
            altText="Inbox Icon"
            buttonText="CV"
            to="/cv"
            routeName="PreviewCV"
          />
					<MenuButtonHeader
						v-else
            className="header-button"
            svgSrc="CV.svg"
            altText="Inbox Icon"
            buttonText="CV"
            toHref="https://lionhead.works/your-profile-0"
            routeName="PreviewCV"
          />
        </div>
        <div class="dropdown">
          <button @click="toggleMenu()" class="logo-button">
            <div v-if="isAuthenticated" class="notification-circle">2<sup>+</sup></div>
            <img class="menu-button" alt="ProfileMenu" src="@/assets/menuVector.svg" />
            <img
              class="profile-button-desktop"
              alt="Profile"
              v-if="profilePictureUrl"
              :src="profilePictureUrl"
            />
            <img v-else class="profile-button" alt="Profile" src="/assets/profileVector.svg" />
          </button>
        </div>
      </div>
    </header>

    <header v-else :class="['header', { 'header-hidden': !isHeaderVisible }]">
      <div class="header-content">
        <button class="logo-button-mobile">
          <router-link to="/" class="logo-link-mobile">
            <img class="logo-mobile" alt="Group" src="@/assets/Skilleon-logo-symbol_blue.svg" />
            <span class="logo-text-mobile">LionHead
              <div class="notification-beta">Beta</div>
            </span>
          </router-link>
        </button>
        <div class="dropdown">
          <button @click="toggleMenu()" class="logo-button-mobile">
            <div class="notification-circle">2<sup>+</sup></div>
						<img v-if="profilePictureUrl" class="profile-img" :src="profilePictureUrl" alt="profile image">
            <img v-else class="profile-button" alt="Profile" :src="'assets/profile.png'" />
          </button>
        </div>
      </div>
    </header>

    <!-- Main Content -->
    <main class="content" :class="[contentContainerClass, { 'desktop-header': !isMobile }]">
			<router-view v-slot="{ Component, route }">
				<transition :name="isMenuPage ? 'slide-fade' : 'none'">
					<span :key="route.name">  
						<component
							:is="Component"
							@child-scroll="handleChildScroll"
							@update-unseen-count="updateUnseenVacanciesCount"
							:isHeaderVisible="isHeaderVisible"
						></component>
					</span>
				</transition>
			</router-view>
    </main>


    <!-- Footer for Mobile -->
    <footer v-if="isMobile" class="footer">
      <div class="footer-content">
        <MenuButton
					v-if="isAuthenticated || profileState"
          property1="default"
          className="additional-class"
          svgSrc="CV.svg"
          altText="Inbox Icon"
          buttonText="CV"
          to="/cv"
          routeName="PreviewCV"
        />
				<MenuButton
					v-else
          property1="default"
          className="additional-class"
          svgSrc="CV.svg"
          altText="Inbox Icon"
          buttonText="CV"
          toHref="https://lionhead.works/your-profile-0"
          routeName="PreviewCV"
        />
        <MenuButton
					v-if="isAuthenticated"
          property1="default"
          className="additional-class"
          svgSrc="bookmarks.svg"
          altText="Saved Icon"
          buttonText="Bookmarks"
          to="/bookmarks"
          routeName="Bookmarks"
        />
				<MenuButton
					v-else
          property1="default"
          className="additional-class"
          svgSrc="bookmarks.svg"
          altText="Saved Icon"
          buttonText="Bookmarks"
					:to="route.path"
          @click="openPopup"
          routeName="Bookmarks"
        />
        <MenuButton
					v-if="!isAuthenticated && route.path == '/cv'"
          className="additional-class"
          svgSrc="skilleon-logo.svg"
          altText="Skilleon Icon"
          buttonText="Match"
          to="/"
					@click="openPopup"
          routeName="Jobs"
          :notification="unseenVacanciesCount"
        />
				<MenuButton
					v-else
          className="additional-class"
          svgSrc="skilleon-logo.svg"
          altText="Skilleon Icon"
          buttonText="Match"
          to="/"
          routeName="Jobs"
          :notification="unseenVacanciesCount"
        />
        <MenuButton
          className="additional-class"
          svgSrc="stairs.svg"
          altText="Ambition Icon"
          buttonText="Ambition"
          to="/ambition"
          routeName="AmbitionQuestionnair"
        />
        <!-- <MenuButton
          property1="default"
          className="additional-class"
          svgSrc="offer.svg"
          altText="Offer Icon"
          buttonText="Offer"
          to="/offers"
          routeName="Offers"
        /> -->
      </div>
    </footer>

    <!-- Feedback Button -->
    <button class="feedback-button" @click="showFeedbackModal = true">
      <img src="@/assets/comment.svg" alt="Feedback" />
    </button>

    <!-- Feedback Modal -->
    <FeedbackModal
      :show="showFeedbackModal"
      @close="showFeedbackModal = false"
      :componentTitle="currentComponentTitle"
      :scrollPosition="scrollPosition"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch, onBeforeMount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import MenuButton from "@/components/CustomComponents/MenuButton.vue";
import MenuButtonHeader from "@/components/CustomComponents/MenuButtonHeader.vue";
import FeedbackModal from "@/components/ProfilePages/feedback.vue";
import Menu from '@/components/ProfilePages/Menu.vue';
import MenuDesktop from '@/components/ProfilePages/MenuDesktop.vue';
import { throttle } from 'lodash'; // Import lodash for throttling
import { auth } from "@/firebase"
import { useDetectMobileDevice } from '../../composables/useDetectMobileDevice';
import { storeToRefs } from 'pinia';
import { useGlobalStore } from '@/store/store';
import { useProfileStore } from '@/store/profile';
import { usePopup } from '@/composables/usePopup';

defineEmits(['childScroll', 'updateUnseenCount']);

const { isOverlayVisible } = storeToRefs(useGlobalStore())
const { profilePictureUrl } = storeToRefs(useProfileStore())
const router = useRouter();
const route = useRoute();

const isHeaderVisible = ref(true);
const lastScrollTop = ref(0);
const showFeedbackModal = ref(false);
const isMenuVisible = ref(false);
const scrollPosition = ref(0);
const lastScrollDirection = ref('down');
const unseenVacanciesCount = ref(0);
let nextRoute = null;

const profileState = localStorage.getItem('profileState') ? JSON.parse(localStorage.getItem('profileState')) : null;

const {isMobile, detectMobileDevice} = useDetectMobileDevice()

// Computed properties
const isMenuPage = computed(() => route.name === 'Menu');
const currentComponentTitle = computed(() => route.name);
const menuTransition = ref('slide-right');

const { openPopup } = usePopup()

const isAuthenticated = computed(() => {
  return auth.currentUser !== null
})

const layoutClass = computed(() => ({
      layout: true,
      // 'mobile-background': !isMobile.value,
      // 'desktop-background': isMobile.value,
    }));


// Handlers
const toggleMenu = () => {
  if (!isMenuVisible.value) {
    // Opening the menu (slide in from the right)
    menuTransition.value = 'slide-right';
    isMenuVisible.value = !isMenuVisible.value;
  } else {
    // Closing the menu (slide out to the right)
    menuTransition.value = 'slide-right';
    isMenuVisible.value = !isMenuVisible.value;
  }
};

const contentContainerClass = computed(() => {
  if (isHeaderVisible.value) {
    return isMobile.value ? 'content-container-with-mobile-header' : 'content-container-with-desktop-header';
  } else {
    return isMobile.value ? 'content-container-no-header' : 'content-container-no-header-desktop';
  }
});


const handleProfileEdit = () => {
  // Set the menu to slide out to the left
  menuTransition.value = 'slide-left';
  isMenuVisible.value = !isMenuVisible.value;
  nextRoute = { name: 'Personal' }; // Store the route, but don't navigate yet
};

const performRouteChange = () => {
  // After the transition, perform the route change
  if (nextRoute) {
    router.push(nextRoute);
    nextRoute = null; // Reset the nextRoute
  }
};

const closeMenu = () => {
  // Close the menu with a slide out to the right
  menuTransition.value = 'slide-right';
  isMenuVisible.value = false;
};

// Scroll Handling
const handleScroll = (event) => {
  const scrollTop = event?.target.scrollTop;
  const scrollHeight = event?.target.scrollHeight;
  const clientHeight = event?.target.clientHeight;

  if (scrollTop + clientHeight >= scrollHeight) {
    // Reached the bottom, keep header hidden
    isHeaderVisible.value = false;
  } else {
    if (scrollTop > lastScrollTop.value) {
      if (lastScrollDirection.value !== 'down') {
        isHeaderVisible.value = false;
        lastScrollDirection.value = 'down';
      }
    } else {
      if (lastScrollDirection.value !== 'up') {
        isHeaderVisible.value = true;
        lastScrollDirection.value = 'up';
      }
    }
  }

  lastScrollTop.value = scrollTop <= 0 ? 0 : scrollTop;
  scrollPosition.value = scrollTop; // Updated to use scrollPosition instead of scrollHeight
};

const throttledHandleScroll = throttle(handleScroll, 100); // Throttle the scroll handler

const handleChildScroll = (event) => {
  throttledHandleScroll(event);
};


// Update unseen vacancies count
const updateUnseenVacanciesCount = (count) => unseenVacanciesCount.value = count;

onBeforeMount(() => {
  detectMobileDevice()
})

// Lifecycle hooks
onMounted(() => {
  console.log('Parent component mounted'); // Debugging statement
  const mainContent = document.querySelector('.content');
  if (mainContent) {
    mainContent.addEventListener('scroll', throttledHandleScroll());
  }
});

onBeforeUnmount(() => {
  // Remove scroll event listener from the main content
  const mainContent = document.querySelector('.content');
  if (mainContent) {
    mainContent.removeEventListener('scroll', throttledHandleScroll());
  }
});
</script>

<style scoped>
.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}
.desktop-background {
  /* background-color: #F5F9FF; */
}

.mobile-background {
  background-color: #FFFFFF; /* or any other color for mobile */
}
.header-desktop {
  flex: 0 0 auto;
  width: 100%;
  height: 70px;
  background: #F5F9FF;
  z-index: 2000;
  touch-action: manipulation; 
  position: fixed;
}

.header-desktop.sidebar-visible {
  background: linear-gradient(
    to right,
    white 350px,
    #F5F9FF 350px
  );
}


.header {
  flex: 0 0 auto;
  width: 100%;
  height: 45px;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  touch-action: manipulation;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.header-route-buttons {
  display: flex;
  flex-direction: row;
  gap: 5px;
  position: relative;
  margin-left: auto;
  right: 50px;
}

.header-hidden {
  transform: translateY(-100%);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 15px;
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo {
  width: 35px;
  height: auto;
}

.logo-text {
  position: relative;
  left: 5px;
  font-family: Gilmer;
  font-style: normal;
  line-height: normal;
  margin-left: 5px;
  font-size: 20px;
  color: #000000;
}

.lionhead-logo-button {
  position: relative;
  cursor: pointer;
  background-color: transparent;
}

.logo-button {
  position: relative;
  cursor: pointer;
  width: 84px;
  height: 46px;
  background: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown {
  position: relative;
}

.menu-button {
  width: 24px;
  height: 24px;
}

.profile-button {
  width: 30px;
  height: auto;
  fill: #056cf2;
  color: #056cf2;
}

.profile-img {
	width: 30px;
  height: auto;
  fill: #056cf2;
  color: #056cf2;
	border-radius: 50%;
}

.profile-button-desktop {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #ced4e0;
  object-fit: cover;
  margin-left: 10px; /* Add spacing if necessary */
}


.content {
  flex: 1 0 auto;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

.no-padding-top {
  padding-top: 0 !important;
}

.content-container-with-mobile-header {
  width: 100%;
  height: 100vh;
	overflow-y: auto;
  padding: 45px 0 60px 0;
}

.content-container-with-desktop-header {
  width: 100%;
  /* Adjust the height if necessary */
  overflow-y: auto;
  box-sizing: border-box;  
  margin-top: 70px;

}

.content-container-no-header {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
}

.content-container-no-header-desktop {
  width: 100%;
  height: calc(100vh);
  overflow-y: auto;
  box-sizing: border-box;
}


.footer {
  flex: 0 0 auto;
  width: 100%;
  height: 60px;
  background: #056cf2;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  touch-action: manipulation;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.notification-circle {
  position: absolute;
  top: -4px;
  left: -8px;
  height: 18px;
  width: 18px;
  background-color: #ff832b;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
  font-weight: light;
}

.notification-beta {
  position: absolute;
  top: 0px;
  right: -28px;
  background-color: #ff832b;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
  font-weight: light;
  z-index: 100;
  padding: 2px 5px;
}

.feedback-button {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #056cf2;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  z-index: 100;
}

.feedback-button img {
  width: 24px;
}

.feedback-button:hover {
  background-color: #045bb5;
}

/* Transition for sliding in from the right */
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.slide-right-enter-from {
  transform: translateX(100%);
  opacity: 0;
}

.slide-right-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.slide-right-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.slide-right-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

/* Transition for sliding out to the left */
.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.slide-left-enter-from {
  transform: translateX(100%);
  opacity: 0;
}

.slide-left-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.slide-left-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.slide-left-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.none-enter-active,
.none-leave-active {
  transition: none;
}

.logo-link-mobile {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-mobile  {
  width: 35px;
  height: auto;
}

.logo-text-mobile  {
  font-family: Gilmer;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #056CF2;
  margin-left: 5px;
  font-size: 20px;
}

.logo-button-mobile  {
  position: relative;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

</style>
