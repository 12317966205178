<template>
  <div>
    <!-- Global Measurement Container outside of any transformed elements -->

    <!-- Conditionally render layout if defined -->
    <template v-if="hasLayout">
      <component :is="layoutComponent">
      </component>
    </template>

    <!-- Render without layout if no layout is defined -->
    <template v-else>
      <router-view />
    </template>

    <ModalPopup />
		<Cropper />
		<Notification />
  </div>
</template>

<script setup>
import { computed, defineComponent, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import HeaderFooterLayout from '@/components/ProfilePages/HeaderFooterLayout.vue';
import ModalPopup from './components/CustomComponents/ModalPopup.vue';
import { usePopup } from './composables/usePopup';

import { auth } from '@/firebase'
import { useGlobalStore } from './store/store';
import Cropper from './components/CustomComponents/Cropper.vue';
import Notification from './components/CustomComponents/Notification.vue';

defineComponent({
  ModalPopup,
	Cropper,
	Notification,
})

// Access the Vuex store
const { isMobileDevice } = storeToRefs(useGlobalStore());

// Access the current route
const route = useRoute();
const router = useRouter()

const { setTexts, setButtonFn, closePopup, registerPopup, setClasses } = usePopup()


// Computed property to set the rootClass based on isMobile

const toAuth = () => {
  router.push({name: 'Login'})
  closePopup()
}

// Remove authToken from localStorage before mounting (if necessary)
onMounted(() => {
  localStorage.removeItem('authToken');

  if (!auth.currentUser) {
    setTexts({
      popupText: `
				Your profile gives you access to permanent opportunities. Log in for free to access all Lionhead services and matching jobs.
			`,
      // secondaryText: `As a Lionhead member, you stay anonymous, and your information is never shared without your consent, giving you control over your career without interference from recruiters who often lack the expertise to truly understand your unique skills and capabilities."`,
      textButton: `Become a LionHead member`
    })
		setClasses({
			buttonClasses: 'w-100',
		})
		registerPopup.value = true
		setButtonFn(registerProcess)
  }

  // Mobile device detection function
  const detectMobileDevice = () => {
    const mediaQueryMatch = matchMedia('(hover: none), (pointer: coarse)').matches;
    const touchEventSupported =
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0;
    const userAgentMatch = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

    return mediaQueryMatch || touchEventSupported || userAgentMatch;
  };

  // Detect mobile device and update Vuex store on first load
  const isMobile = detectMobileDevice(); // Detect if it's a mobile device
  isMobileDevice.value = isMobile; // Commit to Vuex to update state
});

// Computed property to determine if the current route has a layout
const hasLayout = computed(() => {
  return !!route.meta.layout;
});

// const rootClass = computed(() => {
//   console.log("ismobile:", isMobileDevice.value)
//   return isMobileDevice.value ? 'mobile-background' : 'desktop-background';
// });

function registerProcess() {
	router.push({name: 'Register'})
	closePopup()
}


// Computed property to get the layout component based on meta.layout
const layoutComponent = computed(() => {
  const layout = route.meta.layout;
  if (layout === 'HeaderFooterLayout') {
    return HeaderFooterLayout;
  }
  // Add more layouts here if you have them
  // Example:
  // if (layout === 'AnotherLayout') {
  //   return AnotherLayout;
  // }
  console.warn(`Unknown layout "${layout}" specified in route meta.`);
  return null; // Return null if layout is not recognized
});
</script>

<style></style>
