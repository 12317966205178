<template>
	<div class="desktop-menu-overlay" @click.self="closeOnOutsideClick">
		<div v-if="loading" class="desktop-loader-container">
			<div class="desktop-loader"></div>
			<span>Downloading CV...</span>
		</div>

		<div class="desktop-main-container">
			<template v-if="isLoggedIn">
				<!-- Menu Items with Separators -->
				<div v-for="action in desktopActions" :key="action.text">
					<div class="desktop-sub-menu-item" @click="action.handler">
						<div :class="['desktop-frame', action.frameClass]">
							<div :class="action.iconClass" :aria-label="action.text + ' icon'" role="img"></div>
						</div>
						<span class="desktop-action-text">{{ action.text }}</span>
					</div>
					<!-- Separator -->
					<div v-if="action.separator" class="separator"></div>
				</div>
			</template>

			<!-- Login Option for Unauthenticated Users -->
			<template v-else>
				<!-- Learn/FAQ Option -->
				<div class="desktop-sub-menu-item" @click="handleFAQ">
					<div class="desktop-frame frame-learn">
						<div class="learn-icon" aria-label="learn icon" role="img"></div>
					</div>
					<span class="desktop-action-text">FAQ</span>
				</div>
				<div class="desktop-sub-menu-item" @click="openPopup">
					<div class="desktop-frame frame-save">
						<div class="save-profile-icon" aria-label="save-profile-icon" role="img"></div>
					</div>
					<span class="desktop-action-text">Save Profile</span>
				</div>
				<!-- Login Option -->
				<div class="desktop-sub-menu-item" @click="navigateToLogin">
					<div class="desktop-frame frame-login">
						<div class="login-icon" aria-label="login icon" role="img"></div>
					</div>
					<span class="desktop-action-text">Login</span>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import { useRouter } from 'vue-router';
import ProfilePicture from '@/components/ProfilePages/ProfilePicture.vue';
import { computed, ref } from 'vue';
import apiClient from '@/axios';
import CrossIcon from '@/assets/cross.svg';
import { auth } from '@/firebase';
import { useBlurCv } from '../../composables/useBlurCv';
import { useGlobalStore } from '@/store/store';
import { usePopup } from '@/composables/usePopup';
import { useNotification } from '@/composables/useNotification';

export default {
	name: 'MenuPageDesktop',
	components: {
		ProfilePicture,
	},
	setup(props, { emit }) {
		const router = useRouter();
		const { logout, downloadCV, saveState } = useGlobalStore();
		const { addNotification } = useNotification();
		const loading = ref(false);
		const { toggleBlur } = useBlurCv();
		const { openPopup } = usePopup();

		const isLoggedIn = computed(() => {
			return auth.currentUser !== null;
		});

		const goBack = () => {
			emit('closeMenu');
		};

		const closeOnOutsideClick = () => {
			emit('closeMenu');
		};

		const navigateToLogin = () => {
			router.push({ name: 'Login' });
		};

		const EditPersonalInfo = () => {
			emit('editProfile');
		};

		const shareCV = async () => {
			if (navigator.share) {
				try {
					await navigator.share({
						// title: 'Check out Lionhead Works',
						text: `Hey there! 🚀

I just came across this new platform – Lionhead Works! 🌟

It’s perfect for engineers looking for exciting opportunities.  
Here’s why you should check it out:

- Discover tailored job matches across Europe.  
- Build a standout CV and showcase your skills.  
- Stay anonymous while connecting with top employers.  

Take a look and see for yourself!  

🔗 [Lionhead Works](www.lionhead.works)  

Let me know what you think! 😊`,
						// url: window.location.href,
					});
				} catch (error) {
					console.error('Error sharing:', error);
				}
			} else {
				addNotification('Sharing not supported on this browser.', 'close')
			}
		};

		const toggleBlurLocal = () => {
			toggleBlur();
			emit('closeMenu');
		};

		const saveProfile = async () => {
			saveState();
			addNotification('Profile saved!', 'success')
		};

		const handleFAQ = () => {
			window.open('https://lionhead.works/faq-lionhead', '_blank').focus();
		};

		const logoutFn = async () => {
			await logout()
			router.push({ name: 'Login' });
		};

		const downloadCVFn = async () => {
			loading.value = true;
			try {
				await downloadCV();
				await new Promise((resolve) => setTimeout(resolve, 4000)); // Simulated delay
				router.push({ name: 'PreviewCV' });
			} catch (error) {
				console.error('Error downloading CV:', error);
			} finally {
				loading.value = false;
			}
		};

		const deleteUserData = async () => {
			const isConfirmed = confirm(
				'Are you sure you want to delete your profile data? This action cannot be undone.'
			);

			if (isConfirmed) {
				try {
					const response = await apiClient.delete('/delete_user_data');
					if (response.status === 200) {
						await logout();
						router.push({ name: 'Login' });
					} else {
						throw new Error('Failed to delete user data.');
					}
				} catch (error) {
					console.error('Error deleting user data:', error);

					addNotification('Failed to delete user data.', 'close')
				}
			} else {
				console.log('User canceled the delete action');
			}
		};


		const desktopActions = [
			{ text: 'Make resume anonymous', frameClass: 'frame', iconClass: 'lock-icon', handler: toggleBlurLocal },
			{ text: 'Delete profile', iconClass: 'delete', handler: deleteUserData },
			{ text: 'Download CV', frameClass: 'frame-download', iconClass: 'download-icon', handler: downloadCVFn },
			{ text: 'Share CV', frameClass: 'frame-share', iconClass: 'share-icon', handler: shareCV, separator: true },
			{ text: 'Save profile', frameClass: 'frame-save', iconClass: 'save-profile-icon', handler: saveProfile },
			{ text: 'FAQ', frameClass: 'frame-learn', iconClass: 'learn-icon', handler: handleFAQ, separator: true },
			{ text: 'Logout', frameClass: 'frame-logout', iconClass: 'logout-icon', handler: logoutFn },
		];

		return {
			desktopActions,
			goBack,
			EditPersonalInfo,
			CrossIcon,
			loading,
			isLoggedIn,
			closeOnOutsideClick,
			navigateToLogin,
			handleFAQ,
			openPopup
		};
	},
};
</script>



<style scoped>
.login-icon {
	width: 25px;
	height: 25px;
	background: url('@/assets/login.svg') no-repeat center;
	background-size: contain;
}

/* Specific Frame for Login */
.frame-login {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 8px;
	margin-right: 15px;
}

.desktop-menu-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-y: auto;
}

.desktop-main-container {
	position: fixed;
	top: 70px;
	right: 15px;
	min-width: 200px;
	background: #ffffff;
	border-radius: 15px;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
	padding: 20px;
	box-sizing: border-box;

}

.desktop-loader-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.8);
	z-index: 6000;
}

.desktop-loader {
	border: 4px solid #f3f3f3;
	border-top: 4px solid #007bff;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
	margin-bottom: 15px;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.desktop-sub-menu-item {
	display: flex;
	align-items: center;
	padding: 12px 0;
	cursor: pointer;
	/* transition: background-color 0.3s, padding 0.3s; */
	position: relative;
}

.desktop-sub-menu-item:hover {
	background-color: #f5f5f5;
	/* border-radius: 15px; */
	padding: 12px 0;
	margin-left: -20px;
	/* Adjust based on the padding of the main container */
	width: calc(100% + 40px);
	/* Extends to cover the main container padding */
	padding-left: 20px;
	/* Adjust this to maintain the padding from the container */
}

.desktop-frame {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 8px;
	margin-right: 15px;
}

/* Specific Frame Classes (Optional) */
.frame-download,
.frame-share,
.frame-save,
.frame-notifications,
.frame-learn,
.frame-logout {
	/* Add any specific styles for different frames if needed */
}

/* Icon Classes with Descriptive Names */
.lock-icon {
	width: 20px;
	height: 20px;
	background: url('@/assets/lock.svg') no-repeat center;
	background-size: contain;
}

.download-icon {
	width: 20px;
	height: 20px;
	background: url('@/assets/download.svg') no-repeat center;
	background-size: contain;
}

.share-icon {
	width: 20px;
	height: 20px;
	background: url('@/assets/share.svg') no-repeat center;
	background-size: contain;
}

.save-profile-icon {
	width: 25px;
	height: 25px;
	background: url('@/assets/saveProfile.svg') no-repeat center;
	background-size: contain;
}

.learn-icon {
	width: 25px;
	height: 25px;
	background: url('@/assets/question-mark.svg') no-repeat center;
	background-size: contain;
}

.logout-icon {
	width: 20px;
	height: 20px;
	background: url('@/assets/logout.svg') no-repeat center;
	background-size: contain;
}

.delete {
	width: 25px;
	height: 25px;
	background: url('@/assets/delete_forever.svg') no-repeat center;
}

.desktop-action-text {
	font-family: 'Inter', sans-serif;
	font-size: 16px;
	color: #000000;
}

/* Separator Styles */
.separator {
	width: 100%;
	height: 1px;
	background-color: #ccc;

}

/* Responsive Adjustments (Optional) */
@media (max-width: 768px) {
	.desktop-main-container {
		width: 90%;
		padding: 20px;
	}
}
</style>
