// store/modules/profile.js

import apiClient from '@/axios';
import { ref } from 'vue';
import { defineStore } from 'pinia';

// @ts-ignore
import profilePic from '@/assets/profile.png';

function enforceHTTPS(url: string | null) {
	if (!url) {
		console.error('No URL provided');
		return null;  // Return null if the URL is empty
	}

	try {
		const parsedUrl = new URL(url);
		if (parsedUrl.protocol === 'http:') {
			console.warn(`Upgrading insecure URL from HTTP to HTTPS: ${url}`);
			parsedUrl.protocol = 'https:';  // Enforce HTTPS
		}
		return parsedUrl.toString();
	} catch (e: any) {
		console.error('Invalid URL provided:', url, 'Error:', e.message);
		return null;  // Return null for invalid URLs
	}
}

const defaultProfilePicture = profilePic;

export const useProfileStore = defineStore('profile', () => {

	const profilePicture = ref(defaultProfilePicture) // Initialize with default
	const profilePictureUrl = ref(defaultProfilePicture)


	async function uploadProfilePicture(file: File) {
		const formData = new FormData();
		formData.append('image', file);

		try {
			const response = await apiClient.post('/upload_image', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			const imageId = response.data.image_id;
			if (!imageId || imageId.length !== 24) { // Assuming MongoDB ObjectId
				throw new Error('Invalid image ID returned from server');
			}

			// Request a signed URL from the backend
			const signedUrlResponse = await apiClient.get(`/generate-signed-url/${imageId}`);
			let signedUrl = signedUrlResponse.data.signed_url;

			// Enforce HTTPS on signed URL
			signedUrl = enforceHTTPS(signedUrl);

			profilePictureUrl.value = signedUrl;
			profilePicture.value = signedUrl; // Optionally set the profilePicture directly
		} catch (error) {
			console.error('Error uploading image:', error);
		}
	}

	async function fetchAndUploadProfilePicture(url: string) {
		// Enforce HTTPS on the incoming URL
		url = enforceHTTPS(url) as string;
		if (url.startsWith('https://static.licdn.com/aero-v1/')) {
			try {
				const response = await fetch(defaultProfilePicture);
				const blob = await response.blob();
				const fileToUpload = new File([blob], 'profile.png', { type: 'image/png' });
				const objectUrl = URL.createObjectURL(fileToUpload);
				profilePicture.value = objectUrl;
				profilePictureUrl.value = objectUrl;
				uploadProfilePicture(fileToUpload);
			} catch (error) {
				console.error('Error fetching and uploading default profile picture:', error);
			}
		} else {
			try {
				// Fetch the image via the proxy endpoint
				const response = await apiClient.get('/proxy-image', {
					params: { url },
					responseType: 'blob',
				});

				const contentType = response.headers['content-type'] || 'image/jpeg';
				const file = new Blob([response.data], { type: contentType });
				const fileName = url.split('/').pop()?.split('?')[0] || 'profile.jpg';
				const fileToUpload = new File([file], fileName, { type: contentType });
				const objectUrl = URL.createObjectURL(fileToUpload);
				profilePicture.value = objectUrl;
				profilePictureUrl.value = objectUrl;
				await uploadProfilePicture(fileToUpload);
			} catch (error) {
				console.error('Error fetching and uploading image:', error);

				// Fallback to default image
				try {
					const response = await fetch(defaultProfilePicture);
					const blob = await response.blob();
					const fileToUpload = new File([blob], 'profile.png', { type: 'image/png' });
					const objectUrl = URL.createObjectURL(fileToUpload);
					profilePicture.value = objectUrl;
					profilePictureUrl.value = objectUrl;
					await uploadProfilePicture(fileToUpload);
				} catch (fallbackError) {
					console.error('Error uploading fallback profile picture:', fallbackError);
				}
			}
		}
	}

	const loadProfileState = async() => {
		try {
			const response = await apiClient.get('/load_state');
			const { state, isSpecialUser, profilePictureUrl } = response.data;

			if (profilePictureUrl) {
				// Enforce HTTPS on profilePictureUrl
				const secureUrl = enforceHTTPS(profilePictureUrl);

				profilePictureUrl.value = secureUrl;
				profilePicture.value = secureUrl as string;
			}

			// Handle additional state and isSpecialUser logic as needed
		} catch (error) {
			console.error('Error loading profile state:', error);
		}
	}

	return {
		profilePicture,
		profilePictureUrl,
		uploadProfilePicture,
		fetchAndUploadProfilePicture,
		loadProfileState
	}
})
