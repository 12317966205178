import { ref } from 'vue'
import Check from '@/assets/check.svg'
import Warning from '@/assets/warning.svg'
import CrossRed from '@/assets/cross_red.svg'
import Close from '@/assets/close.svg'
import Exclamation from '@/assets/exclamation.svg'
import Info from '@/assets/information.svg'

type CustomNotification = {
	text: string,
	svg: string
}

type NotificationTpe = 'success' | 'error' | 'warning' | 'info' | 'close' | 'exclamation' 

const notificationVisible = ref(false)
const notifications = ref([] as CustomNotification[])
const notificationsLeft = ref(0)
const showCloseButton = ref(true)

export const useNotification = () => {

	const setSvg = (type: string) => {
		switch(type) {
			case 'success':
				return Check
			case 'error':
				return CrossRed
			case 'warning':
				return Warning
			case 'close':
				return Close
			case 'exclamation':
				return Exclamation
			case 'info':
				return Info
			default:
				return Info
		}
	}

	const addNotification = (text: string, type: NotificationTpe) => {
		const svg = setSvg(type)
		notifications.value.push(
			{
				text: text,
				svg: svg
			}
		)
		notificationVisible.value = true
		notificationsLeft.value++
		setTimeout(() => {
			removeNotification()
		}, 5000)
	}

	const removeNotification = () => {
		notifications.value.splice(0, 1)
		notificationsLeft.value--
		if(notificationsLeft.value === 0) {
			notificationVisible.value = false
		}
	}

	return {
		notificationVisible,
		notifications,
		notificationsLeft,
		showCloseButton,
		addNotification,
		removeNotification
	}
}

export default { 
	useNotification
}