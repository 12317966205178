// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBTgV_bLbQsjci7XC1XOE2hGSXg1DbYlxs",
  authDomain: "skilleon-demo.firebaseapp.com",
  projectId: "skilleon-demo",
  storageBucket: "skilleon-demo.appspot.com",
  messagingSenderId: "937823475455",
  appId: "1:937823475455:web:f9813fb51807c6536d9eca",
  measurementId: "G-VGECLJV1ML"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export default { auth };
