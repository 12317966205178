import { ref } from "vue";

const isBlur = ref(false);

export const useBlurCv = () => {

	const toggleBlur = () => {
		isBlur.value = !isBlur.value
	}

	return {
		isBlur,
		toggleBlur
	}
}

export default { useBlurCv }