<template>
	<div v-if="notificationVisible && notification" class="notification-overlay">
		<button v-if="showCloseButton" class="close-btn" @click="close">
			<i class="fa-regular fa-x"></i>
		</button>
		<div class="logo-content">
			<img width="40" src="/logo.png" alt="logo">
			<span class="skilleon">LionHead</span>
		</div>
		<div class="notification-content mt-4 pb-4">
			<img :src="notification.svg" alt="notification-icon" class="notification-icon" />
			<span class="notification-text" v-html="notification.text"></span>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useNotification } from '@/composables/useNotification';
import { computed } from 'vue';

const { notificationVisible, notifications, showCloseButton, removeNotification } = useNotification();

const notification = computed(() => notifications.value[0]);

const close = () => {
	removeNotification();
}
</script>


<style scoped>
.notification-overlay {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 20px;
	width: 400px;
	/* Set a fixed width */
	max-width: 80%;
	/* Optional: Ensure it is responsive on smaller screens */
	z-index: 6001;
	height: 50%;
	text-align: center;

}

.notification-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	max-width: 80%;
}

.notification-icon {
	width: 35px;
	height: 35px;
	margin-bottom: 20px;
}

.notification-text {
	margin-top: 5px;
	font-size: 14px;
}

.close-btn {
	position: absolute;
	top: 20px;
	right: 20px;
	background: none;
	border: none;
	font-size: 16px;
	line-height: 1;
	cursor: pointer;
	color: #7D8AA6;
}

.logo-content {
	display: flex;
	align-items: center;
	position: absolute;
	top: 30px;
	left: 50%;
	transform: translateX(-50%);
}
.skilleon {
  margin-left: 10px;
  font-family: 'Gilmer', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  color: #0d0e10;
}

@media screen and (max-width: 768px) {
	.notification-overlay {
		height: 65%;
		width: 90%;
		max-width: 90%;
	}
	
}
</style>
