import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.css';
import './main.css';
import 'nouislider/dist/nouislider.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { OhVueIcon, addIcons } from 'oh-vue-icons';
import { MdDeleteoutline, FaUserCircle, LaGlobeEuropeSolid, BiWindowSidebar, BiLinkedin, SiOpenai, BiPersonCircle, MdWorkRound, MdDataobjectOutlined, MdSchoolRound, GiMeshNetwork, LaLaptopCodeSolid, GiProgression, BiBarChartLineFill, GiBamboo, FaRoute, CoWeightlifitng } from 'oh-vue-icons/icons';
import apiClient from '@/axios';
import VueGtm from 'vue-gtm';
import { saveStatePlugin } from './store/plugins/saveStatePlugin';
import { useGlobalStore } from './store/store';
import { watch } from 'vue';
import * as Sentry from "@sentry/vue";

try {
  console.log('Initializing icons');
  addIcons(MdDeleteoutline, FaUserCircle, LaGlobeEuropeSolid, BiWindowSidebar, BiLinkedin, SiOpenai, BiPersonCircle, MdWorkRound, MdDataobjectOutlined, MdSchoolRound, GiMeshNetwork, LaLaptopCodeSolid, GiProgression, BiBarChartLineFill, GiBamboo, FaRoute, CoWeightlifitng);

  console.log('Creating Vue app');
  const app = createApp(App);

	const pinia = createPinia();
	pinia.use(saveStatePlugin)
	app.use(pinia);

  // Registering OhVueIcon as a global component
  console.log('Registering v-icon component');
  app.component('v-icon', OhVueIcon);

  console.log('Using router');
  app.use(router);

  // console.log('Using store');
  // app.use(store);

  app.use(VueGtm, {
    id: 'GTM-KBBXH4ZP', // Your GTM container ID
    vueRouter: router, // Pass the router instance
    enabled: true, // Set to false to disable GTM
    debug: false, // Enable to get debug logs
    loadScript: true, // Loads GTM script automatically
    trackOnNextTick: false, // Whether to call trackView in Vue.nextTick
  });

  Sentry.init({
		app,
		dsn: "https://ce2c33e871f5d9bfa6a6972df798ced8@o4508448949272576.ingest.de.sentry.io/4508448955760720",
		integrations: [
			Sentry.browserTracingIntegration({ router }),
			Sentry.replayIntegration(),
		],
		// Tracing
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		enabled: import.meta.env.VITE_APP_ENV !== 'development',
		// environment: import.meta.env.VITE_APP_ENV, // Set the environment
  });

	useGlobalStore().checkLogin().then(() => {
		// watch(
		// 	() => pinia.state.value,  // Watch the entire state
		// 	(state) => {
		// 		const stateToPersist = {
		// 			vacancies: state.vacancies.vacancies,
		// 			bookmarkedVacancies: state.vacancies.bookmarkedVacancies,
		// 			loading: state.vacancies.loading,
		// 			selectedVacancyId: state.vacancies.selectedVacancyId,
		// 			vacanciesByPage: state.vacancies.vacanciesByPage,
		// 			filters: state.vacancies.filters,
		// 			currentPage: state.vacancies.currentPage,
		// 			totalJobsAvailableByFilter: state.vacancies.totalJobsAvailableByFilter,
		// 			downloadTriggered: state.downloadTriggered,
		// 			isSpecialUser: state.isSpecialUser,
		// 			ambitionFormCompleted: state.ambitionFormCompleted,
		// 			lastPageIndex: state.lastPageIndex,
		// 			isMobileDevice: state.isMobileDevice,
		// 			isMobileDeviceDetected: state.isMobileDeviceDetected,
		// 			previousPage: state.previousPage,
		// 			isLoggingOut: state.isLoggingOut,
		// 			isLoggingIn: state.isLoggingIn,
		// 			isOverlayVisible: state.isOverlayVisible,
		// 			editSidebarVisible: state.editSidebarVisible,
		// 			editSidebarSection: state.editSidebarSection
		// 		};
		
		// 		// Save this subset of the state to sessionStorage
		// 		sessionStorage.setItem('my-app-state', JSON.stringify(stateToPersist));
		// 	},
		// 	{ deep: true }  // Make sure to deep watch to capture nested state changes
		// );
		
    console.log('Mounting app');
    app.mount('#app');
    console.log('App mounted successfully');
  }).catch((error) => {
    console.error('Error during app initialization:', error);
    app.mount('#app'); // Ensure the app is mounted even if there's an error
  });

} catch (error) {
  console.error('Error during initialization:', error);
}