<template>
  <div
    :class="['header-button', buttonVariant, className]"
    @mouseover="cursorHover(true)"
    @mouseleave="cursorHover(false)"
    @click.stop="handleClick"
  >
    <router-link
      v-if="to"
      :to="to"
      class="link-wrapper"
    >
      <div class="icon-text-container">
        <div class="notification-circle" v-if="notification > 0">{{ notification }}</div>
        <span class="header-button-text">{{ buttonText }}</span>
      </div>
    </router-link>
		<div v-else-if="toHref" @click="redirectToExternalHttps" class="link-wrapper">
      <div class="icon-text-container">
        <div class="notification-circle" v-if="notification > 0">{{ notification }}</div>
        <span class="header-button-text">{{ buttonText }}</span>
      </div>
    </div>
    <div v-else>
      <div class="icon-text-container">
        <div class="notification-circle" v-if="notification > 0">{{ notification }}</div>
        <div v-html="svgIcon" class="icon"></div>
        <span class="header-button-text">{{ buttonText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useGlobalStore } from '@/store/store';
const svgModules = import.meta.globEager('@/assets/*.svg');

export default {
  name: "MenuButtonHeader",
  props: {
    property1: {
      type: String,
      default: "default",
      validator: function (value) {
        return ["variant-2", "default"].indexOf(value) !== -1;
      },
    },
    className: {
      type: String,
      default: "",
    },
    svgSrc: {
      type: String,
      required: true,
    },
    altText: {
      type: String,
      default: "Vector",
    },
    buttonText: {
      type: String,
      default: "saved",
    },
    to: {
      type: String,
      default: null,
    },
		toHref: {
			type: String,
			default: null,
		},
    routeName: {
      type: String,
      default: null,
    },
    notification: {
      type: [Number, String],
      default: null,
    },
    size: {
      type: Number,
      default: 15,
    },
    overlayValue: { // New prop
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      svgIcon: '',
    };
  },
	setup() {
		const router = useRouter();
		const { isMobileDevice, isOverlayVisible } = storeToRefs(useGlobalStore());

		return {
			isOverlayVisible,
			router,
			isMobileDevice,
		};
	},
  computed: {
    buttonVariant() {
      if (this.overlayValue !== undefined) {
        return this.overlayValue === this.isOverlayVisible && this.$route.path === this.to ? 'variant-2' : 'default';
      }
      return this.$route.name === this.routeName ? 'variant-2' : 'default';
    },

    showNotification() {
      return typeof this.notification === 'number';
    },
    iconSize() {
      return this.size;
    },
  },
  mounted() {
    this.fetchSvgIcon();
  },
  watch: {
    svgSrc: 'fetchSvgIcon',
    $route(to, from) {
      // Recompute the button variant when the route changes
      this.buttonVariant;
    },
  },
  methods: {
    fetchSvgIcon() {
      const svgModule = svgModules[`/src/assets/${this.svgSrc}`];
      if (svgModule) {
        fetch(svgModule.default)
          .then(response => response.text())
          .then(svg => {
            const cleanedSvg = svg.replace(/fill="[^"]*"/g, ''); // Remove existing fill attributes
            this.svgIcon = cleanedSvg;
          })
          .catch(error => console.error('Error fetching SVG:', error));
      } else {
        console.error(`SVG not found: ${this.svgSrc}`);
      }
    },
    cursorHover(isHovering) {
      document.body.style.cursor = isHovering ? "pointer" : "default";
    },
    async handleClick() {
			if (this.overlayValue !== undefined) {
        // For "Match" and "Jobs" buttons
        this.isOverlayVisible = this.overlayValue;
      } else {
        this.isOverlayVisible = false;
      }
    },
		redirectToExternalHttps() {
			window.location.href = this.toHref;
		}
  },
};
</script>

<style scoped>
/* Remove underline from the router-link */
.link-wrapper {
  text-decoration: none;
  color: inherit;
}

.link-wrapper:hover {
  text-decoration: none;
}

/* Button styles */
.header-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  background: #F5F9FF;
  border: none;
  border-radius: 100px;
  transition: background-color 0.3s, color 0.3s;
  font-family: Inter, var(--default-font-family);
  font-size: 14px;
  color: #000;
  position: relative;
  white-space: nowrap;
}

.header-button:hover {
  /* background-color: #fff; */
  color: #000;
}

.header-button:hover .header-button-text {
  font-weight: 500;
  color: #000;
}

.header-button::after {
  content: '';
  position: absolute;
  bottom: 0; /* Align underline at the bottom */
  left: 50%;
  transform: translateX(-50%) scaleX(0);
  width: 60%;
  height: 3px;
  background-color: #056cf2;
  transition: transform 0.3s ease-in-out;
  transform-origin: center;
}

.header-button.variant-2::after { /* Active variant */
  transform: translateX(-50%) scaleX(1);
}

.header-button:hover::after {
  transform: translateX(-50%) scaleX(1.2);
}

.header-button-text {
  font-weight: 500; /* Normal weight */
  color: inherit;
}

.header-button .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  fill: currentColor;
  width: 15px;
  height: 15px;
}

.notification-circle {
  position: absolute;
  top: -4px;
  right: 0px;
  height: 18px;
  width: 18px;
  background-color: #FF832B;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
}

.icon-text-container {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
}
</style>